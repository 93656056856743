var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{style:(("background-color:" + (_vm.themeColors.color1) + "; color:" + (_vm.themeColors.color2) + ";")),attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-2",attrs:{"align":"center"}},[_c('v-col',{attrs:{"justify":"center","cols":"12","sm":"12","md":"5"}},[_c('v-container',[_c('v-row',{staticClass:"d-block"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{style:({ color: _vm.themeColors.color3 })},[_vm._v(" Cotización ")]),_c('h4',{staticClass:"ml-1 my-2"},[_vm._v(_vm._s(_vm.getLabel))]),_c('v-select',{staticClass:"ml-1 mb-1",class:_vm.$vuetify.breakpoint.name == 'xs' ||
									_vm.$vuetify.breakpoint.name == 'sm'
										? 'mb-1'
										: 'ml-1 mb-1',style:(("color:" + (_vm.themeColors.selectTextColor))),attrs:{"no-data-text":"No hay datos disponibles","outlined":"","dense":"","hide-details":"","items":_vm.models,"placeholder":"Seleccionar","background-color":_vm.themeColors.selectColor,"color":_vm.themeColors.selectTextColor,"attach":true},on:{"change":_vm.updateApartmentsSelect},model:{value:(_vm.selectedModel),callback:function ($$v) {_vm.selectedModel=$$v},expression:"selectedModel"}})],1)],1),_c('v-row',{staticClass:"mb-2",attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('div',{staticStyle:{"width":"80%","margin":"0 auto","display":"flex","justify-content":"space-between","text-align":"center"}},[_c('div',[_c('font-awesome-icon',{style:(("color:" + (_vm.themeColors.color2))),attrs:{"icon":['fal', 'lamp-floor']}}),_vm._v(" "+_vm._s(_vm.getApartmentInfo.rooms)+" dormitorio"+_vm._s(_vm.getApartmentInfo.rooms > 1 ? 's' : '')+" ")],1),_c('div',{style:(("border-style: solid; border-color: " + (_vm.themeColors.color2) + "; border-width: 1px;"))}),_c('div',[_c('font-awesome-icon',{style:(("color:" + (_vm.themeColors.color2))),attrs:{"icon":['fal', 'bath']}}),_vm._v(" "+_vm._s(_vm.getApartmentInfo.bathrooms)+" baño"+_vm._s(_vm.getApartmentInfo.bathrooms > 1 ? 's' : '')+" ")],1),_c('div',{style:(("border-style: solid; border-color: " + (_vm.themeColors.color2) + "; border-width: 1px;"))}),_c('div',[_c('font-awesome-icon',{style:(("color:" + (_vm.themeColors.color2))),attrs:{"icon":['fal', 'ruler-combined']}}),_vm._v(" "+_vm._s(_vm.showTotalSurface ? _vm.getApartmentInfo.surface : _vm.getApartmentInfo.util)+"m"),_c('sup',[_vm._v("2")])],1)])])],1),_c('v-select',{staticClass:"mb-1",class:_vm.$vuetify.breakpoint.name == 'xs' ||
							_vm.$vuetify.breakpoint.name == 'sm'
								? 'mb-1'
								: 'mr-1 mb-1',style:(("color:" + (_vm.themeColors.selectTextColor))),attrs:{"no-data-text":"No hay datos disponibles","outlined":"","dense":"","hide-details":"","disabled":_vm.apartmentsDisabled,"items":_vm.apartments,"item-value":function (item) { return item[_vm.propertyNames.id]; },"placeholder":"Seleccionar","background-color":_vm.themeColors.selectColor,"color":_vm.themeColors.selectTextColor,"attach":true},on:{"change":_vm.updateAssetData},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
								var item = ref.item;
return [_c('span',{staticStyle:{"margin-right":"10px"},domProps:{"textContent":_vm._s(
									((item[_vm.propertyNames.assetType]) + " " + (item[_vm.propertyNames.assetNumber]))
								)}}),(item.discountData)?_c('span',[(
										item.discountData[_vm.propertyNames.discountType] ==
											'GRUPO_BIENES'
									)?_c('span',{style:(("margin-left: 3px; color:" + (_vm.colorName(
											item.discountData.color
										)))),domProps:{"textContent":_vm._s(" Reserva hoy con ")}}):_vm._e(),(
										item.discountData[_vm.propertyNames.amountType] ==
											'PORCENTAJE'
									)?_c('span',{style:(("margin-left: 3px; color:" + (_vm.colorName(
											item.discountData.color
										)))),domProps:{"textContent":_vm._s(
										("descuento " + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											item.discountData[_vm.propertyNames.discountAmount] * 100
										)) + "%")
									)}}):_vm._e(),(
										item.discountData[_vm.propertyNames.amountType] == 'CONSTANTE'
									)?_c('span',{style:(("margin-left: 3px; color:" + (_vm.colorName(
											item.discountData.color
										)))),domProps:{"textContent":_vm._s(
										("descuento " + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											item.discountData[_vm.propertyNames.discountAmount]
										)) + " UF")
									)}}):_vm._e(),(
										item.discountData[_vm.propertyNames.amountType] == 'MONTO'
									)?_c('span',{style:(("margin-left: 3px; color:" + (_vm.colorName(
											item.discountData.color
										)))),domProps:{"textContent":_vm._s(
										("descuento $" + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											item.discountData[_vm.propertyNames.discountAmount]
										)))
									)}}):_vm._e(),(
										item.discountData[_vm.propertyNames.amountType] ==
											'MONTO_DESCUENTO_NO_AUTORIZADO_BIEN' &&
											item[_vm.propertyNames.nonAuthorizedDiscount] != 0 &&
											item[_vm.propertyNames.nonAuthorizedDiscount]
									)?_c('span',{style:(("margin-left: 3px; color:" + (_vm.colorName(
											item.discountData.color
										)))),domProps:{"textContent":_vm._s(
										("descuento " + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											item[_vm.propertyNames.nonAuthorizedDiscount] * 100
										)) + "%")
									)}}):_vm._e(),(
										item.discountData[_vm.propertyNames.amountType] ==
											'MONTO_DESCUENTO_BIEN' &&
											item[_vm.propertyNames.authorizedDiscount] != 0 &&
											item[_vm.propertyNames.authorizedDiscount]
									)?_c('span',{style:(("margin-left: 3px; color:" + (_vm.colorName(
											item.discountData.color
										)))),domProps:{"textContent":_vm._s(
										("descuento " + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(item[_vm.propertyNames.authorizedDiscount] * 100)) + "%")
									)}}):_vm._e()]):_vm._e()]}},{key:"item",fn:function(ref){
									var item = ref.item;
return [_c('span',{staticStyle:{"margin-right":"10px"},domProps:{"textContent":_vm._s(
									((item[_vm.propertyNames.assetType]) + " " + (item[_vm.propertyNames.assetNumber]))
								)}}),(item.discountData)?_c('span',[(
										item.discountData[_vm.propertyNames.discountType] ==
											'GRUPO_BIENES'
									)?_c('span',{style:(("margin-left: 3px; color:" + (_vm.colorName(
											item.discountData.color
										)))),domProps:{"textContent":_vm._s(" Reserva hoy con ")}}):_vm._e(),(
										item.discountData[_vm.propertyNames.amountType] ==
											'PORCENTAJE'
									)?_c('span',{style:(("margin-left: 3px; color:" + (_vm.colorName(
											item.discountData.color
										)))),domProps:{"textContent":_vm._s(
										("descuento " + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											item.discountData[_vm.propertyNames.discountAmount] * 100
										)) + "%")
									)}}):_vm._e(),(
										item.discountData[_vm.propertyNames.amountType] == 'CONSTANTE'
									)?_c('span',{style:(("margin-left: 3px; color:" + (_vm.colorName(
											item.discountData.color
										)))),domProps:{"textContent":_vm._s(
										("descuento " + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											item.discountData[_vm.propertyNames.discountAmount]
										)) + " UF")
									)}}):_vm._e(),(
										item.discountData[_vm.propertyNames.amountType] == 'MONTO'
									)?_c('span',{style:(("margin-left: 3px; color:" + (_vm.colorName(
											item.discountData.color
										)))),domProps:{"textContent":_vm._s(
										("descuento $" + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											item.discountData[_vm.propertyNames.discountAmount]
										)))
									)}}):_vm._e(),(
										item.discountData[_vm.propertyNames.amountType] ==
											'MONTO_DESCUENTO_NO_AUTORIZADO_BIEN' &&
											item[_vm.propertyNames.nonAuthorizedDiscount] != 0 &&
											item[_vm.propertyNames.nonAuthorizedDiscount]
									)?_c('span',{style:(("margin-left: 3px; color:" + (_vm.colorName(
											item.discountData.color
										)))),domProps:{"textContent":_vm._s(
										("descuento " + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											item[_vm.propertyNames.nonAuthorizedDiscount] * 100
										)) + "%")
									)}}):_vm._e(),(
										item.discountData[_vm.propertyNames.amountType] ==
											'MONTO_DESCUENTO_BIEN' &&
											item[_vm.propertyNames.authorizedDiscount] != 0 &&
											item[_vm.propertyNames.authorizedDiscount]
									)?_c('span',{style:(("margin-left:3px; color:" + (_vm.colorName(
											item.discountData.color
										)))),domProps:{"textContent":_vm._s(
										("descuento " + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(item[_vm.propertyNames.authorizedDiscount] * 100)) + "%")
									)}}):_vm._e()]):_vm._e()]}}]),model:{value:(_vm.selectedApartmentId),callback:function ($$v) {_vm.selectedApartmentId=$$v},expression:"selectedApartmentId"}}),(_vm.projectData.warehouseService || _vm.projectData.parkingService)?_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[(_vm.getApartmentInfo.associationStatus != 'BIEN_DESVINCULADO')?_c('p',{staticClass:"text-body-1"},[(
									_vm.projectData.warehouseService && _vm.projectData.parkingService
								)?_c('span',{staticClass:"mr-2"},[_vm._v("Seleccionar Estacionamiento y/o Bodega")]):(
									!_vm.projectData.warehouseService && _vm.projectData.parkingService
								)?_c('span',{staticClass:"mr-2"},[_vm._v("Seleccionar Estacionamiento")]):_c('span',{staticClass:"mr-2"},[_vm._v("Seleccionar Bodega")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"#A1A4A1","size":"20"}},[_c('span',_vm._g(_vm._b({staticClass:"white--text",staticStyle:{"font-size":"15px"}},'span',attrs,false),on),[_vm._v("?")])])]}}],null,false,62215343)},[_c('span',[_vm._v("Los bienes que son agregados automáticamente son bienes conjuntos, no se pueden vender por separado")])])],1):_vm._e(),_c('select-sub-assets',{ref:"subAssets",staticClass:"mt-1"})],1):_vm._e()],1),_c('v-row',{staticClass:"mt-6"},[_c('h4',{staticClass:"ml-1 mb-2"},[_vm._v("Tus datos")]),_c('quote-form',{ref:"formRef",attrs:{"text-field-outlined":true,"select-outlined":true,"sm-size":"12"}})],1)],1)],1),_c('v-col',{attrs:{"align":"center","justify":"center","cols":"12","sm":"12","md":"7"}},[(_vm.showProjectLogo)?_c('v-row',[_c('v-col',{attrs:{"align":"right"}},[(_vm.projectData.projectLogoUrl)?_c('img',{staticClass:"mr-2",staticStyle:{"max-height":"200px","max-width":"300px","width":"100%"},attrs:{"src":_vm.projectData.projectLogoUrl}}):_vm._e()])],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',[_c('image-dialog',{style:(("position:relative; max-height: 400, max-width: " + _vm.imgWidth + "; margin-bottom: 8px;")),attrs:{"thumb":("" + (_vm.getApartmentInfo.image)),"full":("" + (_vm.getApartmentInfo.image)),"mobile":this.$vuetify.breakpoint.name == 'xs' ||
							this.$vuetify.breakpoint.name == 'sm'
								? true
								: false}})],1)],1),_c('v-row',[_c('v-col',[(_vm.quoteLimit != null && _vm.quoteLimit <= 0)?_c('p',{staticStyle:{"color":"red"}},[_vm._v(" Ha excedido el número de cotizaciones permitidas por día. ")]):_vm._e(),_c('v-btn',{staticClass:"my-2",style:(("color: " + (_vm.quoteLimit != null && _vm.quoteLimit <= 0
									? '#000'
									: _vm.themeColors.buttonTextColor) + "; font-size: 18px")),attrs:{"id":"moby-quote-button","min-height":"50px","color":_vm.themeColors.color3,"dark":"","block":"","x-large":"","disabled":_vm.quoteLimit != null && _vm.quoteLimit <= 0 ? true : false},on:{"click":function($event){return _vm.$refs.formRef.submitForm()}}},[_vm._v(" "+_vm._s(_vm.textButton)+" ")])],1),(_vm.getOpportunityStatus && !_vm.reservation)?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6"}},[_c('v-btn',{staticClass:"mt-2 ml-sm-2",style:(("color: " + (_vm.themeColors.buttonTextColor) + "; font-size: 16px")),attrs:{"id":"moby-reservation-button","color":_vm.themeColors.color3,"x-large":"","block":""},on:{"click":function($event){return _vm.$refs.formRef.submitForm(true)}}},[_vm._v(" Reservar ")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }